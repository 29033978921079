import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http: HttpClient) { }
  headers: HttpHeaders = new HttpHeaders({
    "Content-Type": "application/json"
  })

  departamento() {
    return fetch(`https://ehrest2.ine.gob.bo/creacion/departamento`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  listaDepartamento() {
    return fetch(`https://ehrest2.ine.gob.bo/creacion/listaDepartamento`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  incidencia() {
    return fetch(`https://ehrest2.ine.gob.bo/conflicto/incidencia`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }
  
  areas() {
    return fetch(`https://ehrest2.ine.gob.bo/conflicto/areas`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  conflictos() {
    return fetch(`https://ehrest2.ine.gob.bo/conflicto/conflictos`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  centros() {
    return fetch(`https://ehrest2.ine.gob.bo/conflicto/centros`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }

  apertura() {
    return fetch(`https://ehrest2.ine.gob.bo/conflicto/apertura`, {
    }).then(data => {
      return data.json();
    }).catch(error=>{
      return false;
    });
  }




}
