

<div class="container-fluid" style="padding: 0.5%;">
    <div class="row">
        <div class="col-md-4">
            <h5 style="text-align: center;">Centros de Operaciones en Areas de Conflicto</h5>
            <div id="container" style="height: 400px;height: 90vh"></div>
        </div>
        <div class="col-md-8">
            <div id="map" class="p-relative" style="height: 96vh;">
                <!--<div class="contenedor-padre">
                    <div class="divContainer1">
                        <h4>Centros de Operaciones en Areas de Conflicto</h4>
                    </div>
                </div>-->
                <div class="divContainer">
                    <ng-select [items]="departamentos" bindLabel="depto" bindValue="cod_depto" placeholder="Seleccione el Departamento" [(ngModel)]="departamento" (change)="actualizarDepto()">
                    </ng-select>
                    <br>
                    <p><i class="pi pi-microsoft" style="color: #FF0000;"></i> Sin reportes</p>
                    <p><i class="pi pi-microsoft" style="color: #FFBD00;"></i> Centro de operaciones Habilitado</p>
                    <p><i class="pi pi-microsoft" style="color: #FFF300;"></i> Centro de operaciones abierto</p>
                    <p><i class="pi pi-microsoft" style="color: #42FF00;"></i> Despliegue de Censistas menor al 50%</p>
                    <p><i class="pi pi-microsoft" style="color: #039D0A;"></i> Despliegue de Censistas mayor al 50%</p>
                    <p><i class="pi pi-microsoft" style="color: #3dd6f1;"></i> Retorno de cajas menor al 50%</p>
                    <p><i class="pi pi-microsoft" style="color: #3d6af1;"></i> Retorno de cajas mayor al 50%</p>
                    <p><i class="pi pi-circle-on" style="color: #980998;"></i> Incidente reportado</p>
                </div>
            </div>
        </div>
    </div>
</div>
