import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as L from 'leaflet';
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import { ServiceService } from '../../service.service';

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css']
})

export class VisorComponent implements OnInit {
  map: L.Map;
  datos1: any;
  markerCluster1: L.LayerGroup = L.layerGroup();
  markerCluster2: L.LayerGroup = L.layerGroup();
  markerCluster3: L.LayerGroup = L.layerGroup();
  departamentos: any;
  provincias: any;
  municipios:any;
  departamento: any;
  provincia: any;
  municipio: any;
  datosDepartamento: any;
  Layer_Edificios: any;
  constructor(private homologacionService: ServiceService,private router: Router) { }

  async ngOnInit() {
    this.departamentos = await this.homologacionService.listaDepartamento();
    this.satelite();
    this.selecionar();
    this.puntos1();
    
  }

  satelite() {
    this.grafico();
    this.map = L.map('map').setView([-16.28174,-65.09399], 6);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
    const tileLayers = {
      satellite: L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          maxZoom: 18,
          tileSize: 512,
          zoomOffset: -1,
      }),

    };
    const controlCapas = L.control.layers(null, null, { position: 'topleft' }).addTo(this.map);
    controlCapas.addOverlay(tileLayers.satellite, 'Satellite');
    this.map.zoomControl.remove();
  }

  async selecionar(){
    const data = await this.homologacionService.departamento()
      this.datosDepartamento = data[0].row_to_json.features;
      const Layer_Manzanas = L.geoJSON(this.datosDepartamento, {
        style: this.estilo_manzanas,
      });
      this.map.addLayer(Layer_Manzanas);

  }

  async puntos1(){
    const data = await this.homologacionService.areas()
      this.datos1 = data[0].row_to_json.features;
      this.Layer_Edificios = L.geoJSON(this.datos1, {
        onEachFeature: this.onEachFeature1.bind(this),
        style: this.estilo_color,
      })
      this.markerCluster1.addLayer(this.Layer_Edificios);
      this.markerCluster1.addTo(this.map);
      this.puntosIncidencia();
  }

  async puntosIncidencia(){
    const data = await this.homologacionService.incidencia()
      this.datos1 = data[0].row_to_json.features;
      this.Layer_Edificios = L.geoJSON(this.datos1, {
        onEachFeature: this.onEachFeature2.bind(this),
        pointToLayer: this.puntoEstilo
    }).addTo(this.map);
  }

  puntoEstilo(feature: any, latlng) {
    return L.circleMarker(latlng, {
      radius: 6,
      fillColor: "#980998",
      color: "#000",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8
  });
  }

  estilo_color(feature: any) {
    return {
      weight: 1,
      color: feature.properties.color,
      fillcolor: '#071A0D',
      opacity: 1.0,
      fillOpacity: 2
    }
  }


  logout() {
    localStorage.removeItem('login');
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  private onEachFeature2(feature, layer): void {
    const valor = feature.properties.incidencias.split(',');
    let texto = `<p><b>INCIDENCIAS</b></p>`
    valor.forEach(element => {
      texto=texto+`<p>${element}</p>`
    });
    const popupContent = texto;
    layer.bindPopup(popupContent)
  }

  private onEachFeature1(feature, layer): void {
    const popupContent = `<h6><b>${feature.properties.mpio}</b></h6>`;
    layer.bindPopup(popupContent)
  }

  estilo_manzanas(feature) {
      return {
        weight: 2.5,
        color: '#071A0D',
        fillcolor: '#071A0D',
        opacity: 1.0,
        fillOpacity: 0.05
      }
  }
  
  actualizarDepto(){
    const ubicacion= this.datosDepartamento.find(res=>res.properties.cod_depto==this.departamento);
    const bounds = L.geoJSON(ubicacion).getBounds();
    this.map.fitBounds(bounds);
  }
  
  async grafico(){
    let container = am4core.create("container", am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";
    const data = await this.homologacionService.conflictos()
    let chart4 = container.createChild(am4charts.XYChart3D);
    chart4.data =data
    let categoryAxis = chart4.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "nombre";
    categoryAxis.renderer.labels.template.rotation = 270;
    categoryAxis.renderer.labels.template.hideOversized = false;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.labels.template.verticalCenter = "middle";
    let valueAxis = chart4.yAxes.push(new am4charts.ValueAxis());

    let series = chart4.series.push(new am4charts.ColumnSeries3D());
    series.dataFields.valueY = "total";
    series.dataFields.categoryX = "nombre";
    series.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = .8;
    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 2;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.stroke = am4core.color("#FFFFFF");
    columnTemplate.adapter.add("fill", function(fill, target: any) {
      return chart4.colors.getIndex(target.dataItem.index);
    })
    columnTemplate.adapter.add("stroke", function(stroke, target: any) {
      return chart4.colors.getIndex(target.dataItem.index);
    })
    chart4.cursor = new am4charts.XYCursor();
    chart4.cursor.lineX.strokeOpacity = 0;
    chart4.cursor.lineY.strokeOpacity = 0;
    chart4.parent = container; 
    //-----------------------
    const data1 = await this.homologacionService.centros();
    console.log(data1)
    let chart = container.createChild(am4charts.XYChart);
    chart.scrollbarY = new am4core.Scrollbar();
    chart.zoomOutButton.disabled = false;
    chart.swipeable = false;
    chart.data = data1;

    let categoryAxis1 = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis1.dataFields.category = "nombre";
    categoryAxis1.numberFormatter.numberFormat = "#";
    categoryAxis1.renderer.inversed = true;
    categoryAxis1.renderer.grid.template.location = 0;
    categoryAxis1.renderer.cellStartLocation = 0;
    categoryAxis1.renderer.cellEndLocation = 0.9;
    categoryAxis1.renderer.minGridDistance = 20;
    categoryAxis1.renderer.labels.template.fontSize = 10;
    //categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.rotation = -10;
    categoryAxis1.renderer.labels.template.truncate = true; 

    let  valueAxis1 = chart.xAxes.push(new am4charts.ValueAxis()); 
    valueAxis1.renderer.fontSize=0;

    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "nombre";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
      series.columns.template.height = am4core.percent(100);
      series.sequencedInterpolation = false;

      let valueLabel = series.bullets.push(new am4charts.LabelBullet());
      valueLabel.label.text = "{valueX}";
      valueLabel.label.horizontalCenter = "left";
      valueLabel.label.dx = 10;
      valueLabel.label.hideOversized = false;
      valueLabel.label.truncate = false;
      valueLabel.label.fontSize = 10;
    };           
    createSeries('total', 'Total');
    createSeries('uno', 'Centro habilitados');
    createSeries('dos', 'Centro Abiertos');

    chart.legend = new am4charts.Legend();
    let title = chart.titles.create();
    title.text = "Centros Habilitados por Departamento";
    title.fontSize = 20;
    title.marginBottom = 10;
    chart.parent = container; 
  }
}